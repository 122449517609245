import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/layout'

const Contact = () => (
  <Layout>
    <div id='main'>
      <section id='abstract'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='global-page-header text-center'>
                <div className='clearfix'>
                  <h2>Contact</h2>
                  <p>
                    Email: <a 
                      rel='noopener noreferrer'
                      href='mailto:hugoelizandro@gmail.com'
                    >
                      hugoelizandro@gmail.com
                    </a>
                  </p>
                  <p>
                    LinkedIn: <a 
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.linkedin.com/in/hugoelizandro/' 
                    >
                      linkedin.com/in/hugoelizandro
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='line-bottom text-center'>
                <Link to='/' className='btn btn-primary'>Home</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Contact
